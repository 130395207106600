@font-face {
    font-family: "thin";
    src: url(./fonts/montserrat/Montserrat-Thin.ttf) format("truetype");
}

@font-face {
    font-family: "light";
    src: url(./fonts/montserrat/Montserrat-Light.ttf) format("truetype");
}

@font-face {
    font-family: "regular";
    src: url(./fonts/montserrat/Montserrat-Medium.ttf) format("truetype");
}

@font-face {
    font-family: "bold";
    src: url(./fonts/montserrat/Montserrat-Bold.ttf) format("truetype");
}

@keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

#first-dot {
    animation-name: fadein;
    animation-duration: 1s;
    animation-delay: 0s;
    opacity: 0;
    animation-fill-mode: forwards;
}
#second-dot {
    animation-name: fadein;
    animation-duration: 1s;
    animation-delay: 1s;
    opacity: 0;
    animation-fill-mode: forwards;
}
#third-dot {
    animation-name: fadein;
    animation-duration: 1s;
    animation-delay: 0.5s;
    opacity: 0;
    animation-fill-mode: forwards;
}

body {
    margin: 0;
    font-family: thin, Sans-Serif;
    width: 100vw;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overscroll-behavior-y: none;
    overscroll-behavior-x: none;
    background-color: #e1d2c8;
    padding-bottom: 30px;
}
.body-wrapper {
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow-x: hidden;
    overscroll-behavior-y: none;
    overscroll-behavior-x: none;
}
